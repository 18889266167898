import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Return from '../components/Forum/Return'
import { useNavigate } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { deleteDataFromApi, registerAction } from '../api'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import EditableItemsCard2 from '../components/EditableItemsCard2'
import ExerciseThumbnail from '../components/Exercise/ExerciseThumbnail'
import EatingHistory from '../components/EatingHistory'
import Button from '../components/Button'

export default function ExerciseHighlight() {

  const { auth, cookies } = React.useContext(AuthContext);
  
  const {
    exercise,
    setExercise,
    highlightedExercise,
    setHighlightedExercise,
    exerciseHistory,
    setExerciseHistory,
  } = React.useContext(PortalContext);
  
  const navigate = useNavigate()
  
  React.useEffect(() => {
  
    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else if (!highlightedExercise) {
      navigate("/profile")
    } //else {
      //loadExerciseDataFromAPI()
    //}
    registerAction(+cookies.get("userId"), "exercisehighlight", auth, cookies)
  }, []);
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const removeExercise = (id) => {
    console.log(`removing exercise ${id}`)
    deleteDataFromApi({"item_id": +id, "id": +cookies.get("userId")}, 'exercise', auth, cookies)

    setExerciseHistory(prev => prev.filter(item => item.id !== id))
    
    const historyUpdated = exercise.history.filter(item => item.id !== id)
    setExercise(prev => ({...prev, history: historyUpdated}))

    setHighlightedExercise({error: true, error_message: "O exercício foi excluído com sucesso"})
  }
  
  const exerciseHistoryFiltered = exerciseHistory.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
  
  if (!highlightedExercise) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando exercício submetido...</p> 
      </div>
    )
  }

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
     
      {(!highlightedExercise.error) ?
      <div className="icon-center top-margin-l">
        <img className="medium-logo" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p class="heading4 bottom-margin-xs">Parabéns!</p> 
        <p class="body">A cada atividade física, você está um passinho mais próximo dos seus objetivos!</p>
      </div> : null }
      <div className="top-margin-l">
        
        {(highlightedExercise.error) ?
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={highlightedExercise.error_message} /> :
          <EditableItemsCard2 
            title="Exercício adicionado"
            icons={false}
          >
            <div>
              <ExerciseThumbnail exercise={highlightedExercise} onRemove={()=>{removeExercise(highlightedExercise.id)}}/>
            </div> 
          </EditableItemsCard2>
        } 
      </div>

      <div style={{textAlign: 'right'}}>
        <Button type="primary top-margin-s" link={true} to={"/profile"}>
          Continuar
        </Button>
      </div>

  </div>
  )
}


