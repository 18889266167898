import React from 'react'
import { useNavigate } from "react-router-dom"
import RemoveIcon from '../../images/remove.svg'
import EditIcon from '../../images/edit.svg'
import BulbIcon from '../../images/info.svg'
import { format } from "date-fns"
import Button from '../Button'
import HighlightedMessage from '../HighlightedMessage'
import Attention from "../../images/sun.svg"
import { putRequestToApi, registerAction } from '../../api'
import { PortalContext } from '../../AuthenticatedUser'
import AuthContext from '../../context/AuthProvider'
import Tag from '../Tag'

export default function FoodThumbnail({ food, onRemove, onEdit=true, icons=true }) {

  const [edit, setEdit] = React.useState(false)
  const [status, setStatus] = React.useState('idle')
  const [error, setError] = React.useState(null)
  const [showSuggestions, setShowSuggestions] = React.useState(false)
  const [showMath, setShowMath] = React.useState(false)
  
  const { 
    //mealsToday, 
    mealsHistory,
    //setMealsToday,
    setMealsHistory,
    highlightedMeal,
    setHighlightedMeal
  } = React.useContext(PortalContext);
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  
  const navigate = useNavigate()

  const [formData, setFormData] = React.useState({
    name: food.name,
    calories: food.calories,
    proteins: food.proteins
  })

  let imageUrl = null
  if (food.hasImage) {
    imageUrl = window.URL.createObjectURL(food.image)
  }
  
  async function submitEdition() {

    setError(null)
    if (formData.name === "" || formData.calories === "" || formData.proteins === "") {
      setError("Por favor, preencha todos os campos")
      return
    }
    if (!(+formData.calories || +formData.calories === 0) || !(+formData.proteins || +formData.proteins === 0)) {
      setError("Os valores de calorias e proteínas devem ser apenas números. Exemplo: 49")
      return
    }
    setStatus("submitting")
    try {
      const requestBody = {
        record_id: food.id,
        id: +cookies.get("userId"), 
        item: {
          name: formData.name,
          calories: formData.calories,
          proteins: formData.proteins
        }
      }

      const data = await putRequestToApi(requestBody, 'food', auth, cookies)
      
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 


    } catch (err) {
      console.log("Error loading data")
      navigate('/loginrequired')
    } finally {

      const mealUpdated = { ...food, name: formData.name, calories: formData.calories, proteins: formData.proteins }

      //mealsToday, 
      //mealsHistory,
      //setMealsToday,
      //setMealsHistory,

      const mealsHistoryUpdated = mealsHistory.filter(item => item.id !== food.id)
      setMealsHistory([...mealsHistoryUpdated, mealUpdated])

      //if (mealsToday.filter(item => item.id === food.id).length > 0) {
      //  const mealsTodayUpdated = mealsToday.filter(item => item.id !== food.id)
      //  setMealsToday([...mealsTodayUpdated, mealUpdated])
      //}

      if (highlightedMeal && highlightedMeal.id === food.id) {
        setHighlightedMeal(prev => ({...prev, name: mealUpdated.name, calories: mealUpdated.calories, proteins: mealUpdated.proteins }))
      }
      
      setEdit(false)
      setStatus("idle")
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    submitEdition()
  }

  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  function recalculate() {

    if (!formData.name || formData.name === "") {
      setError("Por favor, adicione uma descrição para recalcular os valores")
    } else if (food.name === formData.name) {
      setError("Altere ou adicione informações na descrição da refeição para recalcular os valores")
    } else {
      setError(null)
      registerAction(+cookies.get("userId"), "recalculateFood", auth, cookies)

      setHighlightedMeal({...food, name: formData.name})

      navigate('/eating/recalculate')
    }

    return
  }

  const toggleSuggestions = () => {
    setShowMath(false)
    setShowSuggestions(prev=>!prev)
    registerAction(+cookies.get("userId"), "toggleSuggestions", auth, cookies)
  }

  const toggleMath = () => {
    setShowSuggestions(false)
    setShowMath(prev=>!prev)
    registerAction(+cookies.get("userId"), "toggleMath", auth, cookies)
  }

  let tagName = null
  let tagColor = 'green-weak' 
  if (food?.grade) {
    if (food.grade.toUpperCase() === "HEALTHY") {
      tagName = "Saudável"
      tagColor = "green-weak"
    } else if (food.grade.toUpperCase() === "ATTENTION") {
      tagName = "Atenção"
      tagColor = "red"
    } else if (food.grade.toUpperCase() === "SUPER HEALTHY") {
      tagName = "Super saudável"
      tagColor = "green-stark"
    } 
  }

  if (edit) {
    return (
      <div className={`editable-item-for-card-${tagColor} top-margin-xs vertical-padding-xs left-padding-s right-padding-xs`}>     
          {(error) ?
            <div className="bottom-margin-m">
              <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />
            </div> :
            null
          }
        
          <form onSubmit={handleSubmit}>
            <div>
              <div className="bottom-margin-xs">
                <p className="body">Descrição da refeição</p>
                <textarea id="answer" name="name" className="profile-question-form form-full-width shorter-form"  type="text" 
                    placeholder="Descrição da refeição" onChange={handleChange} value={formData.name}/>
              </div>
              <div className="bottom-margin-xs top-margin-xs">
                <p className="body">Quantidade de calorias</p>
                <textarea id="answer" name="calories" className="profile-question-form form-full-width shorter-form" type="text" 
                    placeholder="Quantidade de calorias" onChange={handleChange} value={formData.calories}/>
              </div>
              <div className="bottom-margin-xs top-margin-xs">
                <p className="body">Quantidade de proteínas</p>
                <textarea id="answer" name="proteins" className="profile-question-form form-full-width shorter-form" type="text" 
                    placeholder="Quantidade da proteínas" onChange={handleChange} value={formData.proteins}/>
              </div>
              <Button type="primary" buttonType="button" onClick={()=>recalculate()}>{(status==="idle") ? "Recalcular" : "Enviando..."}</Button>
              <Button type="secondary left-margin-xs">{(status==="idle") ? "Salvar" : "Enviando..."}</Button>
              <Button type="tertiary left-margin-xs" onClick={()=>setEdit(prev=>!prev)}>{"Cancelar"}</Button>
          </div>
          </form>
      </div>
    )
  }

  return (
    <div>
    <div className={`editable-item-for-card-${tagColor} top-margin-xs vertical-padding-xs left-padding-s right-padding-xs`}>     
      <div className='editable-item-for-card-header'>
        <div className='food-image-title-row'>
          {(food.hasImage) ?
          <img className="food-image" src={imageUrl} alt="Food image"/> : null }
          <div>
            {(food?.grade) ?
              <div className="bottom-margin-xxs">
                {(food?.suggestions) ?
                  <button className="svg-btn" onClick={()=>{toggleSuggestions()}}>
                    <div className="row-radio-buttons-narrow" >
                      <Tag color={tagColor}>{tagName}</Tag>
                      <img className="info-icon" src={BulbIcon}/>
                    </div>
                  </button> :
                  <Tag color={tagColor}>{tagName}</Tag>
                }
              </div> : 
              null 
            }
            <p className="body">{food.name}</p> 
            <p className="secondary-text">{food.calories} calorias</p> 
            <p className="secondary-text">{food.proteins} g de proteína</p>

            <div> 
              <span className="secondary-text">Adicionado em {(food.created_at) ? format(food.created_at,"d/M/yyyy") : format(food.date, "d/M/yyyy")}</span>
              {(food?.memorial) ? 
                <>
                  &nbsp;
                  <button className="btn-smaller" onClick={toggleMath}>
                    {(showMath) ? 'Esconder cálculo' : 'Mostrar cálculo'}
                  </button>
                </>
               : null }
            </div>

          </div>
        </div>
        {(icons) ? 
        <div className="row-svg-icons left-margin-xs">
          {(onEdit) ? 
          <button onClick={()=>{setEdit(prev => !prev)}} className='carousel-btn'>
            <img className="" src={EditIcon}/>
          </button> : null }

          <button onClick={()=>{onRemove()}} className='carousel-btn'>
            <img className="" src={RemoveIcon}/>
          </button>
        </div> : null}
      </div>
    </div>
    {(showSuggestions) ? 
      <p className="bottom-appendix-editable-item-for-card secondary-text top-margin-xxs"><strong>Sugestão: </strong>{food.suggestions}</p>
      : null } 
    {(showMath) ? 
      <p className="bottom-appendix-editable-item-for-card secondary-text top-margin-xxs text-wrapping"><strong>Cálculo: </strong>{food.memorial}</p>
      : null } 
    </div>
  )
}
