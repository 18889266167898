import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from '../components/Button'
import Return from '../components/Forum/Return'
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { postRequestToApi, registerAction } from '../api'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import { format, isToday } from "date-fns"
import FoodBreadImage from '../images/bread.svg'
import FoodBananasImage from '../images/bananas.svg'
import FoodFruitImage from '../images/fruit.svg'
import FoodNoodlesImage from '../images/noodles.svg'
import FoodWatermellowImage from '../images/watermellow.svg'
import FoodWrapImage from '../images/wrap.svg'
import MessageBroker from '../components/Submission/MessageBroker'

export default function SubmitImage() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { 
    setMealsHistory,
    setHighlightedMeal,
    eating,
    setEating,
    evolutionBar,
    setEvolutionBar
  } = React.useContext(PortalContext)

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const [imageIndex, setImageIndex] = React.useState(0)

  React.useEffect(() => {
    setImageIndex(Math.floor(Math.random() * 6))

    askForUpdatedMessage()
  }, [])

  const [formData, setFormData] = React.useState({description: ""})
  const [postImage, setPostImage] = React.useState(null)
  const [status, setStatus] = React.useState("idle")

  const [error, setError] = React.useState(null)
  

  const navigate = useNavigate()
  const location = useLocation()
 
  let eatingDate = null
  if (location.state?.date) {
    eatingDate = location.state.date
  }
  let backTo = '/profile'
  if (location.state?.prevPage) {
    backTo = location.state.prevPage
  } 
  if (location.state?.search) {
    backTo = location.state.search;
  } 
  
  async function askForUpdatedMessage() {

    try {

      // call backend to get fresh message
      const requestBody = { id: +cookies.get("userId"), name: "diet" }
      const data = await postRequestToApi(
        requestBody, 
        `/message`, 
        auth, 
        cookies
      )

      setEating(prev => ({...prev, "message": data.message }))
      
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 

    } catch (err) {
      console.log("Error asking for updating message")
      navigate('/loginrequired', { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    }
  }

  async function submitPost() {

    if (formData.description === "" && postImage === null) {
      setError("É necessário adicionar uma imagem ou descrição")
      return
    }

    try {
      setStatus("submitting")
      
      const imageFormData = new FormData()
      imageFormData.append('food', postImage)

      let requestBody = null
      if (eatingDate) {
        requestBody = {
          'description': formData.description,
          'date': eatingDate // format(eatingDate, "MM/dd/yyyy")
        }
      } else {
        requestBody = {
          'description': formData.description,
          'date': new Date()
        }
      }

      const data = await postRequestToApi(
        imageFormData, 
        `food/${cookies.get("userId")}`, 
        auth, 
        cookies, 
        true, 
        false, 
        JSON.stringify(requestBody)
      )

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 

      if (postImage) {
        setHighlightedMeal({
          ...data, 
          created_at: requestBody.date,
          date: requestBody.date,
          hasImage: true,
          image: postImage
        })
        setMealsHistory(prev => [...prev, {
          ...data, 
          created_at: requestBody.date,
          date: requestBody.date,
          hasImage: true,
          image: postImage
        }])
      } else {
        setHighlightedMeal({
          ...data, 
          created_at: requestBody.date, 
          date: requestBody.date,
          hasImage: false, 
          image: null
        })
        setMealsHistory(prev => [...prev, {
          ...data, 
          created_at: requestBody.date, 
          date: requestBody.date,
          hasImage: false, 
          image: null
        }])
      } 
      
      const updatedCount = evolutionBar[format(requestBody.date, "MM/dd/yyyy")]
      if (data.grade.toUpperCase() === "HEALTHY") {
        updatedCount.healthy += 1
        updatedCount.total += 1
      } else if (data.grade.toUpperCase() === "ATTENTION") {
        updatedCount.attention += 1
        updatedCount.total += 1
      } else if (data.grade.toUpperCase() === "SUPER HEALTHY") {
        updatedCount.super_healthy += 1
        updatedCount.total += 1
      } 
      setEvolutionBar(prev => ({...prev, [format(requestBody.date, "MM/dd/yyyy")]: updatedCount }))

      const historyUpdated = eating.history
      historyUpdated.push({date: format(requestBody.date, "MM/dd/yyyy"), id: data.id})
      setEating(prev => ({...prev, history: historyUpdated}))

    } catch (err) {
      console.log("Error submitting food")
      setStatus("error")
      navigate('/loginrequired', { state: { message: "Ocorreu um erro, pedimos que tente novamente mais tarde" }})
    } finally {

      setStatus("complete")
      //navigate('/eating/highlight', { state: { message: "Sua refeição foi submetida com sucesso, e em alguns instantes ela aparecerá nessa página" }})
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    //console.log('submitting')
    submitPost()
  }

  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  function handleImageChange(e) {
    const target = e.target

    setPostImage(target.files[0])
  }
  
  let imageUrl = null
  if (postImage) {
    imageUrl = window.URL.createObjectURL(postImage)
  }


  React.useEffect(() => {
    if (!cookies.get("userId")) {
      navigate('/loginrequired', { state: { pageOfInterest: location.pathname }})
    }
    registerAction(+cookies.get("userId"), "eatingsubmit", auth, cookies)
  }, [])

  if (status !== "idle") {
    return (
      <MessageBroker status={status} submitionItem="refeição" itemDate={eatingDate} />
    )
  }

  return (
    <div className={(tabletBreakpoint) ? "container-simple-tablet top-padding-s" : "container-simple top-padding-s"}>
      <Return to={backTo}/>

      <div className="submit-column">
        <div className="top-margin-xl bottom-margin-l">
          {(eatingDate) ?
            <p className="heading5"><strong>
              {`Adicionar refeição · ${format(eatingDate, "dd/MM/yyyy")}`}
            </strong></p> :
            <p className="heading5"><strong>Adicionar refeição</strong></p>
          }

          {(error) ?
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} /> : null }
        </div> 

        <div>
          {(imageUrl) ? <img className="submit-image-preview" src={imageUrl} alt="Submission image"/> :
          (imageIndex === 0) ? <img className="submission-image" src={FoodWrapImage} alt="Submission image"/> :
          (imageIndex === 1) ? <img className="submission-image" src={FoodWatermellowImage} alt="Submission image"/> :
          (imageIndex === 2) ? <img className="submission-image" src={FoodNoodlesImage} alt="Submission image"/> :
          (imageIndex === 3) ? <img className="submission-image" src={FoodFruitImage} alt="Submission image"/> :
          (imageIndex === 4) ? <img className="submission-image" src={FoodBreadImage} alt="submission image"/> :
          <img className="submission-image" src={FoodBananasImage} alt="submission image"/> }
        </div>


        <form className="top-margin-l form-food-exercise-submit" onSubmit={handleSubmit}>
       
          <Button type="secondary bottom-margin-l" buttonType="button">
            <label for="upload-photo">Selecionar ou tirar foto</label>
          </Button>

          <div>
            <input type="file"
              className="bottom-margin-s"
              id="upload-photo" 
              name="image" 
              accept="image/png, image/gif, image/jpeg, image/jpg" 
              onChange={handleImageChange}
              />
          </div>
          
          <textarea className="submit-exercise-food-form" 
              type="text" 
              name="description" 
              placeholder="Ou você pode adicionar uma descrição (ou fazer os dois!)" 
              onChange={handleChange} 
              value={formData.description}/>
          
          <Button type="primary top-margin-s bottom-margin-m">{(status==="idle") ? "Enviar" : "Enviando..."}</Button>
        </form>
      </div>

    </div>
  )
}

//
