import React from 'react';
import { Outlet } from "react-router-dom"
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from './context/AuthProvider'
import { getDataFromApi, getFoodDataFromApi } from './api'
import { format, isToday } from "date-fns"

const PortalContext = React.createContext()

export default function AuthenticatedUser() {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  //const [userData, setUserData] = React.useState(null);
  const [family, setFamily] = React.useState(null)
  const [health, setHealth] = React.useState({})
  const [eating, setEating] = React.useState(null)
  const [exercise, setExercise] = React.useState(null)

  //const [exerciseWeekLoading, setExerciseWeekLoading] = React.useState(false)
  //const [exerciseWeek, setExerciseWeek] = React.useState([])

  //const [mealsTodayLoading, setMealsTodayLoading] = React.useState(false)
  //const [mealsToday, setMealsToday] = React.useState([])

  const [exerciseHistoryLoading, setExerciseHistoryLoading] = React.useState(false)
  const [exerciseHistory, setExerciseHistory] = React.useState([])
  
  const [mealsHistoryLoading, setMealsHistoryLoading] = React.useState(false)
  const [mealsHistory, setMealsHistory] = React.useState([])
  
  const [highlightedMeal, setHighlightedMeal] = React.useState()
  const [highlightedExercise, setHighlightedExercise] = React.useState()

  const [owner, setOwner] = React.useState(false)

  const [reload, setReload] = React.useState(false)

  const [sequenceLength, setSequenceLength] = React.useState({length: 0, today: false})
  const [evolutionBar, setEvolutionBar] = React.useState(null)

  const location = useLocation()
  const navigate = useNavigate()

  async function loadDataFromAPI() {
    console.log('load data from api - family')
    if (!auth.refreshToken && !cookies.get("refreshToken")) {
      navigate('./loginrequired')
    } else {
      try {
        //const data = await getDataFromApi('myprofile', auth, cookies)
        const data = await getDataFromApi('family', auth, cookies)
        setFamily(data.family)
        setOwner(data.owner)

        //console.log('Authenticated user: back to the main')

        setAuth(prev => ({...prev, ...data.tokens}))
      } catch (err) {
        console.log(err)
        console.log("Could not authenticate user")
        navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
      }
    }
  }

  async function loadEatingDataFromAPI() {
    try {
      const eatingData = await getDataFromApi(`food/today/${+cookies.get("userId")}`, auth, cookies)

      setEating(eatingData)
      
      if (eatingData && eatingData.ids.length > 0) {
        eatingData.ids.forEach((id) => {
          loadMealHistoryFromAPI(id)
        })
      }
      loadSequenceDataFromApi()

    } catch (err) {
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    } 
  }
  
  async function loadExerciseDataFromAPI() {
    try {
      const exerciseData = await getDataFromApi(`exercise/info/${+cookies.get("userId")}`, auth, cookies)

      setExercise(exerciseData)

      if (exerciseData && exerciseData.history.length > 0) {
        exerciseData.ids.forEach((id) => {
          loadExerciseHistoryFromAPI(id)
        })
      }
      loadSequenceDataFromApi()

    } catch (err) {
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    }
  }

  async function loadHealthDataFromAPI(id) {
    try {
      const healthData = await getDataFromApi('userhealth', auth, cookies, {"id": id})
      //console.log(healthData)
      setHealth(prev => ({...prev, [id]: healthData}))

    } catch (err) {
      console.log("Could not authenticate user")
      navigate('./loginrequired', { state : { previousPath: location.pathname }, replace: true})
    }
  }

  async function loadSequenceDataFromApi() {

    const result = await getDataFromApi(`sequence/${+cookies.get("userId")}`, auth, cookies)

    setSequenceLength(result.sequence_length)
    setEvolutionBar(result.evolution_bar)
  } 

  async function loadMealHistoryFromAPI(id) {

    const foodData = await getFoodDataFromApi(`food/${id}`, auth, cookies)

    //const updatedTodaysFood = mealsToday.filter(food => food.id !== id)
    //updatedTodaysFood.push(foodData.food)
    setMealsHistory(prev => [...prev, foodData.food])
  }
  
  async function loadExerciseHistoryFromAPI(id) {

    const exerciseData = await getFoodDataFromApi(`exercise/${id}`, auth, cookies)

    //const updatedTodaysFood = mealsToday.filter(food => food.id !== id)
    //updatedTodaysFood.push(foodData.food)
    setExerciseHistory(prev => [...prev, exerciseData.food])
  }
  
  //React.useEffect(() => {
  //
  //  console.log('get request family - authenticated user')
  //  loadDataFromAPI()
  //
  //}, [reload])

  React.useEffect(() => {
    console.log('load data from api - family - 0 ')

    if (!auth.refreshToken && !cookies.get("refreshToken")) {
      navigate('./loginrequired')
    } else {
      if (!cookies.get('shared')) {
        loadDataFromAPI()
      }
    }

  }, [])

  const calculateSequenceLength = () => {

    let sequenceLengthProvisory = 0

    let historyItems = []

    const exerciseHistoryFiltered = exercise.history.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)
    const mealsHistoryFiltered = eating.history.filter((food, i, arr) => arr.findIndex(obj2 => (obj2.id === food.id)) === i)

    historyItems = historyItems.concat(mealsHistoryFiltered)
    historyItems = historyItems.concat(exerciseHistoryFiltered)

    let todayPresent = false

    const itemsDate = historyItems.map(item => {
      const dateObject = new Date(item.date)
      if (isToday(dateObject)) {
        todayPresent = true
      }
      return format(dateObject, "dd/MM/yyyy") 
    })

    const datesSet = new Set(itemsDate)

    let sequenceContinues = true
    let currentDay = new Date()
    currentDay.setDate(currentDay.getDate() - 1) // start counting yesterday

    while (sequenceContinues) {
      const currentDayFormatted = format(currentDay, "dd/MM/yyyy")
      if (!datesSet.has(currentDayFormatted)) {
        sequenceContinues = false
      } else {
        currentDay.setDate(currentDay.getDate() - 1)
        sequenceLengthProvisory += 1
      }
    }

    if (todayPresent) {
      sequenceLengthProvisory += 1
    }

    setSequenceLength(prev => ({today: todayPresent, length: sequenceLengthProvisory}))

  }

  React.useEffect(() => {
    //loadSequenceDataFromApi()
    if (eating && eating?.history && exercise && exercise?.history) {
      calculateSequenceLength()
    }
  }, [eating, exercise])

  //React.useEffect(() => {
  // 
  //  console.log('loading meals history')
  //  if (!mealsHistoryLoading) {
  //    setMealsHistoryLoading(true)
  //    if (eating && eating.ids.length > 0) {
  //      eating.ids.forEach((id) => {
  //        loadMealHistoryFromAPI(id)
  //      })
  //    }
  //    setMealsHistoryLoading(false)
  //  }
  //}, [eating])
  
  //React.useEffect(() => {
  //
  //  console.log('loading exercise history')
  //  if (!exerciseHistoryLoading) {
  //    setExerciseHistoryLoading(true)
  //    if (exercise && exercise.history.length > 0) {
  //      exercise.ids.forEach((id) => {
  //        loadExerciseHistoryFromAPI(id)
  //      })
  //    }
  //    setExerciseHistoryLoading(false)
  //  }
  //
  //}, [exercise])

  const setTutorialSeen = () => {
      const updatedUserHealth = health[+cookies.get("userId")]
      updatedUserHealth.tutorial = true
      setHealth(prev => ({...prev, [+cookies.get("userId")]: updatedUserHealth}))
  }

  return (
    <PortalContext.Provider value={{
      sequenceLength, 
      family, 
      owner, 
      setReload, 
      auth, 
      cookies, 
      health,
      setHealth,
      eating,
      exercise,
      setEating,
      setExercise,
      loadHealthDataFromAPI, 
      loadEatingDataFromAPI, 
      //mealsToday,
      mealsHistory,
      //setMealsToday,
      setMealsHistory,
      //mealsTodayLoading,
      loadExerciseDataFromAPI,
      //exerciseWeek,
      exerciseHistory,
      //setExerciseWeek,
      setExerciseHistory,
      //exerciseWeekLoading,
      highlightedMeal,
      setHighlightedMeal,
      highlightedExercise,
      setHighlightedExercise,
      setTutorialSeen,
      calculateSequenceLength,
      loadDataFromAPI,
      evolutionBar,
      setEvolutionBar 
    }}>
      <Outlet />
    </PortalContext.Provider>
  )
}

export { PortalContext }




