import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from '../components/Button'
import Return from '../components/Forum/Return'
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { postRequestToApi, registerAction } from '../api'
import HighlightedMessage from '../components/HighlightedMessage'
import Attention from "../images/sun.svg"
import Header from '../components/Header'

export default function Help() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { sequenceLength, eating } = React.useContext(PortalContext);

  const [formData, setFormData] = React.useState({text: ""})
  const [status, setStatus] = React.useState("idle")
  const [error, setError] = React.useState(null)
  const [sent, setSent] = React.useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  
  React.useEffect(() => {
    registerAction(+cookies.get("userId"), "helppage", auth, cookies)
  }, [])

  async function submitMessage() {

    //if (formData.title === "" || formData.text === "") {
    //  setError("Seu post precisa conter título e texto")
    //  return
    //}
    if (formData.text === "") {
      setError("Sua mensagem precisa conter um texto")
      return
    }

    try {
      setStatus("submitting")
      const data = await postRequestToApi({
        "id": cookies.get("userId"),
        "name": formData.text
      }, "userhelp", auth, cookies)
      //setResponse(data)
    } catch (err) {
      console.log("Error loading forum data")
      navigate('/loginrequired')
    } finally {
      setSent(true)
      setStatus("idle")
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    submitMessage()
  }

  function handleChange(e) {
    const {name, value} = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  React.useEffect(() => {
    if (!cookies.get("userId")) {
      navigate('/loginrequired', { state: { pageOfInterest: location.pathname }})
    }
  }, [])


  return (

    <div className="container-full-height">
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header 
          tabletBreakpoint={false} 
          menuItems="portal" 
          id={+cookies.get("userId")} 
          menuShow={true}
          sequenceLength={sequenceLength}
          hasObjectives={(eating && eating?.goals) ? eating.goals.length > 0 : false}
        />
      </div>
      
      {(sent) ?
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
          <div className="top-margin-xl bottom-margin-xs">
            <Return to={`/profile`} backTo="Página inicial"/>
          </div>  
          
          <p className="florence-title">Mensagem Enviada!</p>
          <p className="body top-margin-xs secondary-color">
            Obrigado pelo seu contato! Enviaremos a resposta em seu e-mail cadastrado
          </p>
        </div>
      :
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
          <div className="top-margin-xl bottom-margin-xs">
            <Return to={`/profile`} backTo="Página inicial"/>
          </div>  
          
          <p className="florence-title">Enviar Mensagem</p>
          <p className="body top-margin-xs secondary-color">
            Agradecemos todo o feedback e avisos de problema em nosso sistema!
            Responderemos sua mensagem em até 24 horas pelo seu e-mail cadastrado
          </p>

          {(error) ?
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} /> : null }

          <form className="top-margin-s" onSubmit={handleSubmit}>

            <textarea className="post-form top-margin-xs" type="text" 
                name="text" placeholder="Digite sua mensagem" onChange={handleChange} value={formData.text}/>
            
            <Button type="primary top-margin-s bottom-margin-m">{(status==="idle") ? "Enviar" : "Enviando..."}</Button>
          </form>
        </div>
      }
    </div>
  )
}

//
