import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from "../Button"
import HighlightedMessage from "../HighlightedMessage"
import Attention from "../../images/sun.svg"
import { calculateTDEE } from "./WeightLossForm"
import { PortalContext } from '../../AuthenticatedUser'
import EditableItemsCard2 from '../EditableItemsCard2'
import { postRequestToApi, registerAction } from '../../api'

export default function EnergyExpenditureCard() {
  
  const phoneBreakpoint = useMediaQuery({ query: '(max-width: 520px)' })
  
  const { 
    eating,
    setEating,
    auth, 
    cookies } = React.useContext(PortalContext);
  
  const [tdeeCalculated, setTdeeCalculated] = React.useState(false)
  const [tdeeConfirmed, setTdeeConfirmed] = React.useState(null)
  const [tdee, setTdee] = React.useState({})
  const [error, setError] = React.useState(null)
  const [activityString, setActivityString] = React.useState("")
  const [showFactors, setShowFactors] = React.useState(false)
  const [calculate, setCalculate] = React.useState(false)
  
  const userRef = React.useRef()

  const [formData, setFormData] = React.useState({
    height: "", weight: "", activityLevel: "", sex: "", age: ""
  })

  React.useEffect(() => {
    
    const id = +cookies.get("userId")

    if (eating?.tdee && eating.tdee !== null) {
      setTdeeConfirmed(eating.tdee)
      
      if (eating.sex && 
        eating.age && 
        (eating?.height) && 
        (eating?.weight) && 
        eating?.activity_level) {

        const tdee = calculateTDEE({age: eating.age, sex: eating.sex}, 
          {
            'height': eating.height,
            'weight': eating.weight,
            'activityLevel': eating.activity_level
          }
        )

        setTdeeConfirmed(Math.round(tdee.tdee))
      }
    }

    setFormData({
      sex: eating.sex,
      age: eating.age,
      height: (eating?.height) ? +eating.height : "",
      weight: (eating?.weight) ? +eating.weight : "",
      activityLevel: (eating?.activity_level) ? `${eating.activity_level}` : ""
    })

    if (eating?.activity_level || eating.activity_level) {
      if (+eating.activity_level === 1.15) {
        setActivityString("Sedentário")
      } else if (+eating.activity_level === 1.25) {
        setActivityString("Levemente ativo")
      } else if (+eating.activity_level === 1.3) {
        setActivityString("Moderadamente ativo")
      } else if (+eating.activity_level === 1.45) {
        setActivityString("Altamente ativo")
      }
    }

  }, [eating])

  async function submitUpdatedData(bodyRequest) {
    await postRequestToApi(bodyRequest, '/food/goals', auth, cookies)

    registerAction(+cookies.get("userId"), "calculatecaloryexpernditure", auth, cookies)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (formData.activityLevel === null || formData.activityLevel === "") {
      setError("Por favor, selecione um nível de atividade física")
      return
    }
    if (isNaN(formData.height) || isNaN(formData.weight)) {
      setError("Por favor, insira valores válidos para altura (em metros), e peso (em kilogramas). Use ponto para separar os números, por exemplo: 1.69")
      return
    }
    if (formData.weight > 300) {
      setError("Por favor, o valor de peso deve ser inferior a 300 kg")
      return
    }
    if (formData.weight < 10) {
      setError("Por favor, o valor de peso deve ser superior a 10 kg")
      return
    }
    if (formData.height > 3) {
      setError("Por favor, o valor da altura deve ser inferior a 3 m")
      return
    }
    if (formData.height < 0.5) {
      setError("Por favor, o valor da altura deve ser superior a 0.5 m")
      return
    }

    if (formData.height !== "" && formData.weight !== "") {
      setError(null)
    
      setCalculate(false)

      console.log('submitted')
      console.log(formData)

      const tdee = calculateTDEE({age: formData.age, sex: formData.sex}, formData)
      setTdee(tdee)
      setTdeeCalculated(true)

      const updatedHealth = eating
      updatedHealth['activity_level'] = formData.activityLevel 
      updatedHealth['weight'] = formData.weight
      updatedHealth['height'] = formData.height
      updatedHealth['tdee'] = Math.round(tdee.tdee)

      setEating(prev => ({...prev, ...updatedHealth}))
  
      submitUpdatedData({
        id: +cookies.get("userId"), 
        exam_data: {
          'height': +formData.height, 
          'weight': +formData.weight,
          'activity_level': +formData.activityLevel,
          'tdee': Math.round(tdee.tdee)
        }})

    } else {
      setError("Por favor, preencha todos os campos")
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const tdeeResult = (
    <div>
      <p className="body">Em média {tdeeConfirmed} calorias</p>
      {showFactors ? 
        <div>
          <p className="secondary-text">Sexo: {eating.sex}</p>
          <p className="secondary-text">Idade: {eating.age} anos</p>
          <p className="secondary-text">Altura: {eating.height} m</p>
          <p className="secondary-text">Peso: {eating.weight} kg</p>
          <p className="secondary-text">Atividade física: {activityString}</p>
        </div> 
      : null }
      <Button type="tertiary top-margin-xs" onClick={()=>setShowFactors(prev=>!prev)}>
        {showFactors ?
          "Esconder fatores" : "Mostrar fatores"
        }
      </Button>
    </div>
  )
  
  const userForm = (
    <>
      
      {(error) ?
        <div className="">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />  
        </div>
        : null }

      <p className="body capitalize"><strong>Sexo: </strong>{formData.sex}</p>
      <p className="body top-padding-xs"><strong>Idade: </strong>{formData.age}</p>

      <form onSubmit={handleSubmit} className="login-form">
        <p className="body top-padding-xs">Altura (m)</p>
        <input
          className="input-text bottom-margin-xs"
          ref = {userRef}
          name="height"
          onChange={handleChange}
          type="text"
          value={formData.height}
        />

        <p className="body">Peso (kg)</p>
        <input
          className="input-text bottom-margin-xs"
          name="weight"
          onChange={handleChange}
          type="text"
          value={formData.weight}
        />

        <p className="body bottom-margin-xs">Nível de atividade física</p>
        <div className={(phoneBreakpoint) ? "row-radio-buttons-single bottom-margin-xs" : "row-radio-buttons-narrow bottom-margin-xs"}>
          <div className="closed-radio-button">
            <label className="body"><strong>Sedentário</strong>: Pouco ou nenhum exercício</label>
            <input
              name="activityLevel"
              id = "activityLevel0"
              onClick={handleChange}
              type="radio"
              value="1.15"
              checked={"1.15" === formData.activityLevel}
            />
          </div>
          <div className="closed-radio-button">
            <label className="body"><strong>Levemente ativo</strong>: Exercício leve 1-3x por semana</label>
            <input
              name="activityLevel"
              id = "activityLevel1"
              onClick={handleChange}
              type="radio"
              value="1.25"
              checked={"1.25" === formData.activityLevel}
            />
          </div>
          <div className="closed-radio-button">
            <label className="body"><strong>Moderadamente ativo</strong>: Exercício moderado 3-5x por semana</label>
            <input
              name="activityLevel"
              id = "activityLevel2"
              onClick={handleChange}
              type="radio"
              value="1.3"
              checked={"1.3" === formData.activityLevel}
            />
          </div>
          <div className="closed-radio-button">
            <label className="body"><strong>Altamente ativo</strong>: Exercício intenso 6-7x por semana</label>
            <input
              name="activityLevel"
              id = "activityLevel3"
              onClick={handleChange}
              type="radio"
              value="1.45"
              checked={"1.45" === formData.activityLevel}
            />
          </div>
        </div>

        <div className="top-margin-xs">
          <Button type="primary top-margin-xs bottom-margin-xs">
            Calcular 
          </Button>
          <button type="button" className="btn-tertiary left-margin-s top-margin-xs bottom-margin-xs" onClick={()=>{setCalculate(false)}}>
            Cancelar 
          </button>
        </div>
      </form>
    </>
  )
 
  return (
    <EditableItemsCard2 
      title="Gasto diário de calorias"
      icons={false}
      userAdd={false}
    >

      {(tdeeConfirmed) ?
        <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
          {tdeeResult}
        </div> : 
        null }
      {(calculate) ?
        <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
          {userForm}
        </div> :         
        <div className="left-margin-s top-margin-s">
          <Button type="secondary" onClick={()=>{setCalculate(true)}}>{(tdeeConfirmed) ? 'Recalcular' : 'Calcular' }</Button>
        </div> }

    </EditableItemsCard2>
  )
}

