import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { differenceInDays, isAfter } from "date-fns"
import Header from "../components/Header"
import EditableItemsCard2 from "../components/EditableItemsCard2"
import EditableItemForCard2 from "../components/EditableItemForCard2"
import MedicationForm from "../components/Health/MedicationForm"
import CalendarHistory from "../components/CalendarHistory"
import PlotCard from "../components/PlotCard"
import Return from "../components/Forum/Return"
import AuthContext from '../context/AuthProvider'
import { PortalContext } from '../AuthenticatedUser'
import { postRequestToApi, putRequestToApi, deleteDataFromApi, registerAction } from '../api'

export default function HealthAdherence() {

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const { 
    auth, 
    cookies 
  } = React.useContext(AuthContext);

  const { 
    health,
    setHealth,
    exercise,
    eating,
    loadExerciseDataFromAPI, 
    loadEatingDataFromAPI, 
    loadHealthDataFromAPI, 
    setReload, 
    sequenceLength 
  } = React.useContext(PortalContext);
  
  const [historyItemsData, setHistoryItemsData] = React.useState(null)
  const [userHealth, setUserHealth] = React.useState({
    name: null,
    age: null,
    smoking: "Não informado"
  })
  
  const navigate = useNavigate()

  // go to the top of the page
  React.useEffect(() => {

    if (!cookies.get("userId")) {
      navigate("/family")
    } else {
      // condition on exercise, eating and health data being empty
      if (!eating) {
        loadEatingDataFromAPI()
      }
      if (!exercise) {
        loadExerciseDataFromAPI()
      }
      if (!health[+cookies.get("userId")]) {
        loadHealthDataFromAPI(+cookies.get("userId"))
      }
    }

    registerAction(+cookies.get("userId"), "medicationadherencehistorypage", auth, cookies)
  }, []);

  React.useEffect(() => {
    const id = +cookies.get("userId")

    if (health[id]) {
      setUserHealth({
        name: `${health[id].first_name} ${health[id].last_name}`,
        age: `${health[id].age}`,
        smoking: health[id].smoking_status ? `${health[id].smoking_status}` : 'Não informado',
        organ_donation: health[id].organ_donation ? health[id].organ_donation : 'Não declarado',
        blood_type: health[id].organ_donation ? health[id].blood_type : 'Não informado',
        sus_number: health[id].sus_number ? health[id].sus_number : 'Não informado',
        emergency_contact: (health[id].emergency_contact.name)
          ? health[id].emergency_contact : {name: "Não informado", phone_number: null},
        insurance: health[id].insurance ? health[id].insurance : [],
        medication: (health[id].medication || health[id].medication.length===0 ) ? health[id].medication : [],
        allergy: (health[id].allergy || health[id].allergy.length===0 ) ? health[id].allergy : [],
        exam: (health[id].exams || health[id].disease.exams===0 ) ? 
          health[id].exams.map(exam => ({...exam, show: false}))  
          : [],
        prescription: (health[id].prescriptions) ? 
          health[id].prescriptions : {},
        vaccine: (health[id].vaccines) ? 
          health[id].vaccines : [],
        disease: (health[id].disease || health[id].disease.length===0 ) ? health[id].disease : [],
        nextAppointment: health[id].next_appointment ? `${health[id].next_appointment}` : 'Não informado',
        height: health[id].height ? `${health[id].height}` : 'Não informado',
        weight: health[id].weight ? `${health[id].weight}` : 'Não informado',
        bmi: health[id].bmi ? `${health[id].bmi}` : null,
        healthHistory: (health[id].health_history || health[id].health_history.length===0 ) ? health[id].health_history : [],
        familyHealthHistory: (health[id].family_health_history || health[id].family_health_history.length===0 ) ? 
          health[id].family_health_history : [],
        medicationAdherence: (health[id].medication_adherence) ? 
          health[id].medication_adherence.map(item => ({
            id: item.id, date: new Date(item.date), frequency: item.frequency, medication: item.medication, status: item.status
        })) : []
      })

    }
  }, [health])

  React.useEffect(() => {

    let confirmed = 0
    let pendent = 0

    if (userHealth.medicationAdherence) {
      userHealth.medicationAdherence.forEach((item) => {
        if (differenceInDays(new Date(), item.date) <= 6) {
          if (item.status === "Confirmado") {
            confirmed += 1
          } else if (item.status === "Pendente") {
            if (!isAfter(item.date, new Date())) {
              pendent += 1
            }
          }
        }
      })
    }
    
    setHistoryItemsData([
      {"class": "Confirmado", "colour": "green", "value": confirmed},
      {"class": "Pendente", "colour": "red", "value": pendent}
    ])

  }, [userHealth])

  const onMedicationConfirmation = (itemId) => {

    const newArray = []
    userHealth.medicationAdherence.forEach((item) => {
      if (item.id === itemId) {
        newArray.push({...item, status: "Confirmado"})
      } else {
        newArray.push(item)
      }
    })

    setUserHealth(prev => ({...prev, medicationAdherence: newArray}))

    const userHealthDict = health[+cookies.get("userId")]
    
    setHealth(prev => ({...prev, [+cookies.get("userId")]: {...userHealthDict, medication_adherence: newArray}}))

    registerAction(+cookies.get("userId"), "medicationadherenceconfirmation", auth, cookies)
    const body = {
      "id": +cookies.get("userId"),
      "item_id": itemId
    }
    postRequestToApi(body, 'adherence', auth, cookies)
  }

  const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
 
  return (
    (!userHealth.name) ? 
    <div className="icon-loading-middle-page">
      <img className="small-logo-nav loading-icon" 
          src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
      <p>Carregando página...</p> 
    </div>
    :  
    <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header 
          tabletBreakpoint={false} 
          menuItems="portal" 
          id={+cookies.get("userId")} 
          menuShow={true}
          sequenceLength={sequenceLength}
          hasObjectives={(eating && eating?.goals) ? eating.goals.length > 0 : false}
          />
      </div>
      <>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

        <div className="top-margin-xl bottom-margin-xs">
          <Return to={`/profile`} backTo="Página inicial"/>
        </div>

        <p className="florence-title bottom-margin-l">Portal de Saúde</p>

        <div className="content-two-columns">
          <Link className="thumbnail-link" to="/health">
            <p className="body">Dados de saúde</p>
          </Link>
          <Link className="thumbnail-link" to="/health/adherence">
            <p className="body"><strong>Uso de medicação</strong></p>
          </Link>
        </div>
        <div className="progress-bar">
          <div className="rectangle empty"></div>
          <div className="rectangle full"></div>
        </div>

        <div className="vertical-padding-l">
          <CalendarHistory medicationReminders={userHealth.medicationAdherence} 
            title="Tomada de remédios "
            onCardConfirmation={onMedicationConfirmation}
          />
        </div>

        <div className="container-simple">
          {(historyItemsData) ? 
            <PlotCard type="bar" title="Tomada de medicamento últimos 7 dias" data={historyItemsData}/> : 
            null }
        </div>


      </div>
    </>
  </>
  )
}
