import React from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import FoodIcon from "../images/meal.svg"
import RunIcon from "../images/running.svg"
import { PortalContext } from '../AuthenticatedUser'
import { deleteDataFromApi, postRequestToApi, registerAction } from '../api';
import AuthContext from "../context/AuthProvider"
import { ForumContext } from '../forum/ForumData'
import EditableItemsCard2 from '../components/EditableItemsCard2';
import EatingObjectiveCard from '../components/Eating/EatingObjectiveCard';
import RemoveIcon from '../images/remove.svg'
import Bulb from "../images/bulbDark.svg"
import HighlightedMessage from '../components/HighlightedMessage';
import EatingPlanSuggestionsCard from '../components/Eating/EatingPlanSuggestionsCard';
import PlanThumbnail from '../components/Eating/PlanThumbnail';
import LastActivities from '../components/LastActivities';
import CalendarHistory from '../components/CalendarHistory';
import { format } from "date-fns"
import Button from '../components/Button';
import EvolutionBar from '../components/EvolutionBar';

export default function Profile() {

  //const {id} = useParams()
  const location = useLocation()
  const { auth, cookies } = React.useContext(AuthContext);
  const {
    sequenceLength,
    health,
    setHealth,
    exercise,
    eating,
    setEating,
    loadExerciseDataFromAPI, 
    loadEatingDataFromAPI, 
    loadHealthDataFromAPI 
  } = React.useContext(PortalContext);

  const [addObjective, setAddObjective] = React.useState(false)
  const [addPlan, setAddPlan] = React.useState(false)
  const [userHealth, setUserHealth] = React.useState({medicationAdherence: []})
  const [showReminders, setShowReminders] = React.useState(true)
  
  const [textIndex, setTextIndex] = React.useState(0)

  React.useEffect(() => {
    setTextIndex(Math.floor(Math.random() * 4))
  }, [])

  const { notifications } = React.useContext(ForumContext)
  
  const smallMobileBreakpoint = useMediaQuery({ query: '(max-width: 360px)' })
  const mobileBreakpoint = useMediaQuery({ query: '(max-width: 480px)' })
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  //const laptopBreakpoint = useMediaQuery({ query: '(min-width: 1024px)' })
  //const desktopBreakpoint = useMediaQuery({ query: '(min-width: 1280px)' })

  const navigate = useNavigate()

  const name = cookies.get("userName") 

  cookies.set('pageOfInterest', null, { path: '/' })
  //cookies.set("userId", id, { path: '/' })
  
  React.useEffect(() => {
    if (health[+cookies.get("userId")]) {
      setUserHealth({
        medicationAdherence: (health[+cookies.get("userId")].medication_adherence) ? 
          health[+cookies.get("userId")].medication_adherence.map(item => ({
            id: item.id, 
            date: new Date(item.date), 
            frequency: item.frequency, 
            medication: item.medication, 
            status: item.status
        })) : []
      })
    }
  }, [health])

  React.useEffect(() => { 
    if (!cookies.get("userId")) {
      navigate("/family")
    } else {
      // condition on exercise, eating and health data being empty
      if (!eating) {
        loadEatingDataFromAPI()
      }
      if (!exercise) {
        loadExerciseDataFromAPI()
      }
      if (!health[+cookies.get("userId")]) {
        loadHealthDataFromAPI(+cookies.get("userId"))
      }
    }
    registerAction(+cookies.get("userId"), "profilepage", auth, cookies)
  }, [])

  if (!exercise || !eating || !health) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }


  //if (health[+cookies.get("userId")]) {
  //  if (health[+cookies.get("userId")].tutorial !== true) {
  //
  //    setTutorialSeen()
  //
  //    const requestBody = {
  //      id: +cookies.get("userId")
  //    }
  //    postRequestToApi(requestBody, '/tutorialseen', auth, cookies)
  //
  //    navigate("/tutorial")
  //  }
  //}
  
  const onMedicationConfirmation = (itemId) => {

    const newArray = []
    userHealth.medicationAdherence.forEach((item) => {
      if (item.id === itemId) {
        newArray.push({...item, status: "Confirmado"})
      } else {
        newArray.push(item)
      }
    })

    setUserHealth(prev => ({...prev, medicationAdherence: newArray}))

    const userHealthDict = health[+cookies.get("userId")]
    
    setHealth(prev => ({...prev, [+cookies.get("userId")]: {...userHealthDict, medication_adherence: newArray}}))

    registerAction(+cookies.get("userId"), "medicationadherenceconfirmation", auth, cookies)
    const body = {
      "id": +cookies.get("userId"),
      "item_id": itemId
    }
    postRequestToApi(body, 'adherence', auth, cookies)
  }
  
  const deleteObjective = (id) => {
    deleteDataFromApi(
      {
        "id": +cookies.get("userId"), 
        "item_id": id
      }, 'food/goals', auth, cookies)

    const updatedGoals = eating.goals.filter(item => item.id !== id)  
    setEating(prev => ({...prev, goals: updatedGoals}))
  }
  
  const deletePlan = (id) => {
    deleteDataFromApi(
      {
        "id": +cookies.get("userId"), 
        "item_id": id
      }, 'food/plan', auth, cookies)

    const updatedPlan = eating.plan.filter(item => item.id !== id)  
    setEating(prev => ({...prev, plan: updatedPlan}))
  }

  // filter todays medication alerts
  const todayFilter = [format(new Date, "dd/MM/yyyy")]
  
  let remindersAfterFilter = userHealth.medicationAdherence.filter(item => {
          return (todayFilter.includes(format(item.date, "dd/MM/yyyy")) && item.status === "Pendente")
  })
  
  const planSuggestions = [
    {
      "title": "Planejar refeições da semana",
      "content": `Refeições para essa semana

- Segunda-feira:
- Terça-feira:
- Quarta-feira:
- Quinta-feira:
- Sexta-feira:
- Sábado:
- Domingo:`
    },
    {
      "title": "O que vou fazer quando der vontade de comer algo que não deveria",
      "content": "Quando tiver vontade de comer (algo que não deveria), vou..."
    },
    {
      "title": "Quais dias e horários vou me exercitar",
      "content": `Exercícios da semana:`
    },
    {
      "title": "O que vou fazer quando der vontade de não ir na academia",
      "content": `Quando der vontade de não ir, eu vou...`
    },
    {
      "title": "A cada atividade física que eu fizer, minha recompensa vai ser",
      "content": "Minha recompensa por fazer atividade física será..."
    },
  ]

  let welcomeTextEarlyMorning = null
  let welcomeTextMorning = null
  let welcomeTextEarlyAfternoon = null
  let welcomeTextAfternoon = null
  let welcomeTextEvening = null
 
  if (!eating || !eating?.sex || !eating?.first_name) {

    loadEatingDataFromAPI()
    loadExerciseDataFromAPI()
    loadHealthDataFromAPI(+cookies.get("userId"))

    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }

  let welcomeNewUser = `Bem ${(eating.sex.toLowerCase() === "feminino") ? "vinda" : "vindo"}, ${eating?.first_name}! Comece adicionando uma refeição ou exercício`

  if (cookies.get("userName")) {
    welcomeTextEarlyMorning = [
      `Oi ${eating?.first_name}! Algum exercício hoje cedo?`,
      `Bom dia ${eating?.first_name}! Algum exercício hoje cedo?`,
      `Bom dia ${eating?.first_name}! Como foi o café da manhã hoje?`,
      `Oi ${eating?.first_name}! Alguma fruta para começar bem o dia?`,
    ]
    welcomeTextMorning = [
      `Oi ${eating?.first_name}! Como está sua manhã?`,
      `Oi ${eating?.first_name}! Comeu alguma coisa hoje de manhã?`,
      `Oi ${eating?.first_name}! Alguma fruta hoje de manhã?`,
      `Oi ${eating?.first_name}! Algum exercício hoje de manhã?`,
    ]
    welcomeTextEarlyAfternoon = [
      `Oi ${eating?.first_name}! Como foi o almoço hoje?`,
      `Boa tarde ${eating?.first_name}! Como estava o almoço?`,
      `Oi ${eating?.first_name}! Como está? Fez uma caminhadinha após o almoço?`,
      `Oi ${eating?.first_name}! Alguma fruta de sobremesa no almoço?`,
    ]
    welcomeTextAfternoon = [
      `Oi ${eating?.first_name}! Algum lanche hoje a tarde?`,
      `Oi ${eating?.first_name}! Algum exercício hoje a tarde?`,
      `Oi ${eating?.first_name}! Lembre-se de esticar as pernas e beber bastante água`,
      `Oi ${eating?.first_name}! Uma fruta a tarde vai bem, hein`,
    ]
    welcomeTextEvening = [
      `Oi ${eating?.first_name}! Como está a janta?`,
      `Oi ${eating?.first_name}! Algum lanche hoje a noite?`,
      `Oi ${eating?.first_name}! Algum exercício hoje?`,
      `Oi ${eating?.first_name}! Algum exercício para fechar bem o dia?`,
    ]
  }

  const timeNow = new Date();
  const hoursNow = timeNow.getHours();

  let messageText = null

  if (hoursNow < 10 && hoursNow >= 4) {
    messageText = welcomeTextEarlyMorning
  } else if (hoursNow < 12) {
    messageText = welcomeTextMorning
  } else if (hoursNow < 15) {
    messageText = welcomeTextEarlyAfternoon
  } else if (hoursNow < 19) {
    messageText = welcomeTextAfternoon
  } else {
    messageText = welcomeTextEvening
  }

  const content = (
      <section>
        
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
      
          <div className="column-message-profile">
            {(remindersAfterFilter.length > 0 && showReminders) ?
              <div className="top-margin-s">
                <CalendarHistory medicationReminders={remindersAfterFilter} 
                  title="Lembretes pendentes de "
                  onCardConfirmation={onMedicationConfirmation}
                  showCalendar={false}
                  accessButtons={false}
                />
              </div>
              : null 
            }
            <div className="top-padding-l bottom-padding-l horizontal-padding-xxs">
              <p className="florence-title center-text">
              {(location.state && location.state === 'new user') ? welcomeNewUser : messageText[textIndex]}
              </p>
            </div>
          </div>

          <div className={(tabletBreakpoint) ? "pair-btn-row bottom-margin-xl" : "pair-btn-row bottom-margin-m"}>
            <Button 
              type={(smallMobileBreakpoint) ? "primary btn-wide btn-no-padding btn-taller" :"primary btn-wide btn-taller"}
              link={true} 
              to="/eating/submit">
              Adicionar refeição
            </Button>
            <Button 
              type={(smallMobileBreakpoint) ? "primary btn-wide btn-no-padding btn-taller" :"primary btn-wide btn-taller"}
              link={true} 
              to="/exercise/submit">
              Adicionar exercício
            </Button>
          </div>

          <div className={(tabletBreakpoint) ? "bottom-margin-xl" : "bottom-margin-l"}>
            <EvolutionBar />
          </div>

          <div className="bottom-margin-m">
            <LastActivities tabletBreakpoint={tabletBreakpoint} />
          </div>
          
        </div>

      </section>
  )

//  const menuItems = laptopBreakpoint ? [
//    {icon: <img className="menu-icon" src={ProfileIcon}/>, selected: true,  label: "Início", link: "#", type: "Navegue" },
//    {icon: <img className="menu-icon" src={PillWhite}/>, label: "Dados de Saúde", link: "#", type: "Navegue" },    
//    {icon: <img className="menu-icon" src={ExerciseWhite}/>, label: "Exercícios Físicos", link: "#", type: "Navegue" },   
//    {icon: <img className="menu-icon" src={FoodWhite}/>, label: "Alimentação", link: "#", type: "Navegue" },
//    {icon: <img className="menu-icon" src={Bulb}/>, label: "Assistente de Saúde", link: "#", type: "Navegue" },
//    {icon: <img className="menu-icon" src={FamilyIcon}/>, label: "Família", link: "#", type: "Configurações" },
//    {icon: <img className="menu-icon" src={Account}/>, label: "Sua Conta", link: "#", type: "Configurações" },
//    {icon: <img className="menu-icon" src={LogOut}/>, label: "Sair", link: "#", type: "Configurações"}
//  ] : [
//   {element: <strong>Inicio</strong>, link: "#"},
//    {element: <>Dados de Saúde</>, link: "#"},    
//    {element: <>Exercícios Físicos</>, link: "#"},   
//    {element: <>Alimentação</>, link: "#"},
//    {element: <>Assistente de Saúde</>, link: "#"},
//    {element: <>Família</>, link: "#"},
//    {element: <>Sua Conta</>, link: "#"},
//    {element: <>Sair</>, link: "#"}
//  ] 

  //    {
  //    laptopBreakpoint ? 
  //      <div className="lateral-menu">
  //        <MenuColumn menuItems={menuItems} user={name}/>
  //        <div className={desktopBreakpoint ? "container container-no-border container-two-columns":"container container-no-border"}>
  //          <Header menuShow={false}/>
  //
  //          {content}
  //
  //        </div>
  //      </div> 
  //    :
  return (
    <>
      <>
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
          <Header tabletBreakpoint={false} 
            menuItems="profile" 
            id={+cookies.get("userId")} 
            menuShow={true}
            notifications={notifications}
            selected="user-home"
            sequenceLength={sequenceLength}
            hasObjectives={(eating && eating?.goals) ? eating.goals.length > 0 : false}
          />
        </div>

        {content}
        <br/>

      </>
    </>
  )
}
