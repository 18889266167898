import React from "react"
import { Link } from "react-router-dom"
import ToggleMenu from "../components/ToggleMenu.js"
import HeaderLinks from "./HeaderLinks.js"
import FireWhite from "../images/fire-white.svg"
import FireRed from "../images/fire-red.svg"
import FireBlue from "../images/fire-blue.svg"
import BullseyeBlue from "../images/bullseye-blue.svg"
import BullseyeRed from "../images/bullseye-red.svg"
import BullseyeWhite from "../images/bullseye-white.svg"
//import TrophyBackWhite from "../images/trophy-white.svg"
//import TrophyBackBlue from "../images/trophy-blue.svg"
//import HeartBackWhite from "../images/heart-white.svg"
//import HeartBackBlue from "../images/heart-blue.svg"

export default function Header({
  tabletBreakpoint, 
  menuItems, 
  id, 
  menuShow, 
  notifications=[],
  loggedIn=true,
  selected="",
  sequenceLength="",
  hasObjectives=false,
}) {
  
  //<Link to="/">
  //  {(selected==="competition") ?
  //    <div className="nav-icon-blue-background">
  //      <img className="small-icon-nav icon-nav-horizontal-padding" src={TrophyBackBlue} alt="Florence logo"/>
  //    </div> :
  //    <div className="nav-icon-white-background">
  //      <img className="small-icon-nav icon-nav-horizontal-padding" src={TrophyBackWhite} alt="Florence logo"/>
  //    </div>
  //  }
  //</Link>
  //
  //<Link to="/">
  //  {(selected==="friends") ?
  //  <div className="nav-icon-red-background right-padding-xs">
  //    <img className="small-icon-nav icon-nav-horizontal-padding" src={HeartBackBlue} alt="Florence logo"/>
  //  </div> : 
  //  <div className="nav-icon-white-background">
  //   <img className="small-icon-nav icon-nav-horizontal-padding" src={HeartBackWhite} alt="Florence logo"/>
  //  </div>
  //  }
  //</Link>

  const showMessage = false 
  const message = "Nosso sistema passará por atualização e ficará indisponível das 14h15 às 15h15 hoje, 14/11. Agradecemos a compreensão!"

  return (
    <div>
      {(showMessage) ? 
      <div className="header-message top-margin-xs">
        <strong>Atenção: </strong>{ message }
      </div> : null }

      <nav className={(tabletBreakpoint) ? "top-padding-xs" : "top-padding-xs"}>
        {(loggedIn) ?
        <div className="nav-left-buttons">
          <Link to={(loggedIn) ? "/profile" : "/"}>
            {(selected==="user-home") ? 
              <div>
                <img className="small-blue-logo-nav" src={require("../images/logo-florence.png")} alt="Florence logo"/>
              </div>
              :
              <div>
                <img className="small-logo-nav" src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
              </div>
            }
          </Link>
          <Link className="typeface-no-margin" to="/profile/history">
            {(selected==="sequence") ?
              <div className={(sequenceLength.today) ? "nav-icon-red-background right-padding-xs" : "nav-icon-blue-background right-padding-xs"}>
                <div className="icon-paragraph">
                  <img className="small-icon-nav icon-nav-left-padding" src={FireWhite} alt="Florence logo"/>
                  <p className="heading2 sans-serif-typeface typeface-no-margin-red">{sequenceLength.length}</p>
                </div>
              </div> :
              <div className="nav-icon-white-background right-padding-xs">
                <div className="icon-paragraph">
                  <img className="small-icon-nav icon-nav-left-padding" src={(sequenceLength.today) ? FireRed : FireBlue} alt="Florence logo"/>
                  <p className="heading2 sans-serif-typeface typeface-no-margin">{sequenceLength.length}</p>
                </div> 
              </div> 
            }
          </Link>
          
            <Link className="typeface-no-margin" to="/profile/objectives">
            {(selected==="objectives") ?
              <div className={(hasObjectives) ? "nav-icon-red-background right-padding-xs" : "nav-icon-blue-background right-padding-xs"}>
                <div className="icon-paragraph">
                  <img className="small-icon-nav icon-nav-left-padding" src={BullseyeWhite} alt="Florence logo"/>
                </div>
              </div> :
              <div className="nav-icon-white-background right-padding-xs">
                <div className="icon-paragraph">
                  <img className="small-icon-nav icon-nav-left-padding" src={(hasObjectives) ? BullseyeRed : BullseyeBlue} alt="Florence logo"/>
                </div> 
              </div> 
            }
          </Link>

        </div>
        :
        <div className="nav-left-buttons">
          <Link to="/">
            {(selected==="user-home") ? 
              <div>
                <img className="small-blue-logo-nav" src={require("../images/logo-florence.png")} alt="Florence logo"/>
              </div>
              :
              <div>
                <img className="small-logo-nav" src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
              </div>
            }
          </Link>
        </div>
        }

        { (tabletBreakpoint) ?
          <HeaderLinks menuItems={menuItems} id={id} /> :
          ( menuShow ? <ToggleMenu menuItems={menuItems} id={id} notifications={notifications}/> : null )
        }
      </nav>
    </div>
  )
}
