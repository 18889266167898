import React from "react"

export default function SimpleWaitMessage({ submitionItem }) {

  return (
    <div className="icon-loading-middle-page">
      <img className="small-logo-nav loading-icon" 
          src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
      <p>{`Submetendo ${submitionItem}...`}</p> 
    </div>
  )
}
