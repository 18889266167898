import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Button from "../Button"
import Attention from "../../images/sun.svg"
import { calculateTDEE } from "./WeightLossForm"
import { PortalContext } from '../../AuthenticatedUser'
import EditableItemsCard2 from '../EditableItemsCard2'
import { putRequestToApi } from '../../api'
import HighlightedMessage from '../HighlightedMessage'

export default function EatingObjectiveCard({ setAddCard }) {

  const { 
    auth, 
    cookies,
    eating,
    setEating 
  } = React.useContext(PortalContext);


  const [error, setError] = React.useState(null)
  const [formData, setFormData] = React.useState({
    objective: ""
  })
  const [objective, setObjective] = React.useState(null)
  
  async function submitUpdatedData(bodyRequest) {
    await putRequestToApi(bodyRequest, '/food/goals', auth, cookies) 
  }

  const addObjective = () => {
    
    if (formData.objective !== "") {
      setError(null)

      const updatedGoals = eating.goals
      updatedGoals.push({
        content: formData.objective,
        manually_added: true,
        title: null
      })
      setEating(prev => ({...prev, goals: updatedGoals}))
      
      submitUpdatedData({
        id: +cookies.get("userId"), 
        name: formData.objective 
      })

      setAddCard(false)
    } else {
      setError("Por favor, preencha todos os campos")
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault()
    
    addObjective()
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const objectiveTagSelected = (objectiveClicked) => {
    if (objective !== objectiveClicked) {
      setObjective(objectiveClicked)
    } else {
      setObjective(null)
    }
  }

  const createPreMadeObjective = (objectiveText) => {
    const updatedGoals = eating.goals
    updatedGoals.push({
      content: objectiveText,
      manually_added: true,
      title: null
    })
    setEating(prev => ({...prev, goals: updatedGoals}))
    
    submitUpdatedData({
      id: +cookies.get("userId"), 
      name: objectiveText
    })

    setObjective(null)
    setAddCard(false)
  }
 
  const weightGainText = "Permite calcular a quantidade de calorias e proteínas diárias para você ganhar massa"
  const weightLossText = "Permite calcular a quantidade de calorias e proteínas diárias para você perder peso"
  //const cholesterolControlText = "Mais tarde vamos calcular juntos seu peso saudável e te dar dicas sobre os melhores alimentos para você"
  
  //return (
  //  <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
  //    <div className="btn-grid top-padding-xxs">
  //      <Button type="secondary" link={true} to='/eating/objective/weigthloss'>Perda de peso</Button>
  //      <Button type="secondary" link={true} to='/eating/objective/cholesterolcontrol'>Controle do colesterol</Button>
  //      <Button type="secondary" link={true} to='/eating/objective/hypertensioncontrol'>Controle da hipertensão</Button>
  //      <Button type="secondary" link={true} to='/eating/objective/hearthealth'>Saúde do coração</Button>
  //      <Button type="secondary" link={true} to='/eating/objective/hypertrophy'>Ganho de massa muscular</Button>
  //    </div>
  //    {(error) ? <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} /> : null} 
  //    <form onSubmit={handleSubmit} className="top-margin-xs">
  //      <textarea name="objective" className="profile-question-form form-full-width shorter-form" 
  //        onChange={handleChange} type="text" placeholder="Adicione objetivo personalizado"
  //        value={formData.objective}/> 
  //      <Button type="primary top-margin-xs">Criar</Button>
  //      <button type="button" className="btn-tertiary left-margin-s" onClick={()=>{setAddCard()}}>Cancelar</button>
  //    </form>
  //
  //  </div>
  //)
  //
  
  //      <div>
  //        <button className={(objective==="cholesterolControl") ? "tag-selected" : "tag-filter"} 
  //          onClick={()=>objectiveTagSelected("cholesterolControl")}>
  //          Controlar o colesterol
  //        </button>
  //        {(objective === "cholesterolControl") ? 
  //          <>
  //            <p className="bottom-appendix-editable-item-for-card secondary-text text-wrapping top-margin-xxs">
  //              {cholesterolControlText}
  //            <div>
  //              <Button type="smaller" onClick={()=>createPreMadeObjective("Perder peso")}>Adicionar objetivo</Button>
  //            </div>
  //            </p>
  //          </>
  //        : null}
  //      </div>
  //
  return (
    <div className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs">
      <div className="btn-grid top-padding-xxs bottom-padding-xxs">
        <div>
          <button className={(objective==="weightGain") ? "tag-selected" : "tag-filter"} 
            onClick={()=>objectiveTagSelected("weightGain")}>
            Ganhar massa muscular
          </button>
          {(objective === "weightGain") ? 
            <>
              <p className="bottom-appendix-editable-item-for-card secondary-text text-wrapping top-margin-xxs">
                {weightGainText}
              <div>
                <Button type="smaller" onClick={()=>createPreMadeObjective("Ganhar massa muscular")}>Adicionar objetivo</Button>
              </div>
              </p>
            </>
          : null}
        </div>
        
        <div>
          <button className={(objective==="weightLoss") ? "tag-selected" : "tag-filter"} 
            onClick={()=>objectiveTagSelected("weightLoss")}>
            Perder peso
          </button>
          {(objective === "weightLoss") ? 
            <>
              <div className="bottom-appendix-editable-item-for-card secondary-text text-wrapping top-margin-xxs">
                {weightLossText}
                <div>
                  <Button type="smaller" onClick={()=>createPreMadeObjective("Perder peso")}>Adicionar objetivo</Button>
                </div>
              </div>
            </>
          : null}
        </div>
        

      </div>

      {(error) ? 
        <div className="bottom-margin-m">
          <HighlightedMessage icon={Attention} type="warning" header="Atenção" body={error} />
        </div>
        : null
      } 
      <form onSubmit={handleSubmit} className="top-margin-xs">
        <textarea name="objective" className="profile-question-form form-full-width shorter-form" 
          onChange={handleChange} type="text" placeholder="Escreva seu objetivo"
          value={formData.objective}/> 
        <Button type="primary top-margin-xs">Adicinar</Button>
        <button type="button" className="btn-tertiary left-margin-s" onClick={()=>{setAddCard()}}>Cancelar</button>
      </form>

    </div>
  )
}
