import React from "react"
import { PortalContext } from "../../AuthenticatedUser"
import Button from "../Button"
import HighlightedMessage from "../HighlightedMessage"
import Bulb from "../../images/bulbDark.svg"

export default function FlorenceMessage({ clickNext, submitionItem }) {
  
  const { eating, exercise } = React.useContext(PortalContext)

  if (submitionItem === "refeição" && !eating.message) {
    clickNext()
    return null
  } else if (submitionItem === "exercício" && !exercise.message) {
    clickNext()
    return null
  }

  return (
    <div className={"container-simple"}>
      <div className="column-middle-page">
        <div className="icon-center">
          <img className="medium-logo" 
              src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        </div>

        <HighlightedMessage icon={Bulb} type="message" header="Mensagem da Florence" body={(submitionItem === "refeição") ? eating.message : exercise.message } />
        <div className="top-margin-s" style={{textAlign: 'right'}}>
          <Button type="primary" onClick={()=>{clickNext()}}>Próximo</Button>
        </div>
      </div>
    </div>
  )
}
