import React from 'react'
import Header from "../components/Header"
import { PortalContext } from "../AuthenticatedUser"
import RemoveIcon from '../images/remove.svg'
import Fisherman from '../images/sport-fisherman.svg'
import EditableItemsCard2 from "../components/EditableItemsCard2"
import EatingObjectiveCard from "../components/Eating/EatingObjectiveCard"
import { deleteDataFromApi, registerAction } from '../api'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import EatingPlanSuggestionsCard from '../components/Eating/EatingPlanSuggestionsCard'
import PlanThumbnail from '../components/Eating/PlanThumbnail'
import WeightLossThumbnail from '../components/Eating/WeightLossThumbnail'
import HypertrophyThumbnail from '../components/Eating/HypertrophyThumbnail'

export default function UserObjectives() {
  
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  const [addObjective, setAddObjective] = React.useState(false)
  const [addPlan, setAddPlan] = React.useState(false)

  const {
    sequenceLength,
    eating,
    setEating,
    loadEatingDataFromAPI,
    auth, 
    cookies } = React.useContext(PortalContext);
  
  const navigate = useNavigate()
  
  React.useEffect(() => {
    if (!cookies.get("userId")) {
      cookies.set('pageOfInterest', '/eating', { path: '/' })
      navigate("/family")
    } else {
      if (!eating || !eating?.goals) {
        loadEatingDataFromAPI()
      }
    }

    registerAction(+cookies.get("userId"), "objectivespage", auth, cookies)

    if (eating?.plan.length === 0) {
      setAddPlan(true)
    }

  }, []);
 
  const deleteObjective = (id) => {
    deleteDataFromApi(
      {
        "id": +cookies.get("userId"), 
        "item_id": id
      }, 'food/goals', auth, cookies)

    const updatedGoals = eating.goals.filter(item => item.id !== id)  
    setEating(prev => ({...prev, goals: updatedGoals}))
  }
  
  const deletePlan = (id) => {
    deleteDataFromApi(
      {
        "id": +cookies.get("userId"), 
        "item_id": id
      }, 'food/plan', auth, cookies)

    const updatedPlan = eating.plan.filter(item => item.id !== id)  
    setEating(prev => ({...prev, plan: updatedPlan}))
  }


  if (!eating?.goals || !eating?.plan) {
    return (
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    )
  }
  
  const planSuggestions = [
    {
      "title": "O que vou fazer... onde... e quando...",
      "content": "O que vou fazer... onde... e quando..."
    },
    {
      "title": "O que vou fazer quando der vontade de comer algo que não deveria",
      "content": "Quando tiver vontade de comer (algo que não deveria), vou..."
    },
    {
      "title": "Quais dias e horários vou me exercitar",
      "content": `Exercícios da semana:`
    },
    {
      "title": "O que vou fazer quando der vontade de não ir na academia",
      "content": `Quando der vontade de não ir, eu vou...`
    },
    {
      "title": "A cada atividade física que eu fizer, minha recompensa vai ser",
      "content": "Minha recompensa por fazer atividade física será..."
    },
  ]

  const goalsWeightLossFiltered = eating.goals.filter(item => item.content.toLowerCase().includes("perder peso"))  
  const hasWeightLoss = goalsWeightLossFiltered.length 
  
  const goalsWeightGainFiltered = eating.goals.filter(item => item.content.toLowerCase().includes("ganhar massa"))  
  const hasWeightGain = goalsWeightGainFiltered.length 


  return (
      <>
        <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
          <Header 
            tabletBreakpoint={false} 
            menuItems="portal" 
            id={+cookies.get("userId")} 
            menuShow={true} 
            selected="objectives"
            sequenceLength={sequenceLength}
            hasObjectives={(eating && eating?.goals) ? eating.goals.length > 0 : false}
          />
        </div>
        <>
          <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>

          <div className="">
            <p className="florence-title top-margin-s">
              Foco e planejamento!
            </p>
            <p className="body top-margin-m bottom-margin-xxs">
              1. Defina seus objetivos
            </p> 
            <p className="body bottom-margin-s secondary-color">
              Exemplo: Praticar exercício físico 3x por semana
            </p> 
            <p className="body bottom-margin-xxs">
              2. Faça planos semanais para atingí-los
            </p> 
            <p className="body bottom-margin-xxs secondary-color">
              Exemplo: (O que vou fazer) Vou correr 30 minutos 
            </p> 
            <p className="body bottom-margin-xxs secondary-color">
              (Onde) na rua
            </p> 
            <p className="body bottom-margin-m secondary-color">
              (Quando) segunda, quarta e sexta-feira logo que acordar
            </p> 
          </div>

      <EditableItemsCard2
        clsName="bottom-margin-m" 
        title="Objetivos"
        icons={true}
        addCard={()=>setAddObjective(prev=>!prev)}
      >
        <div>
          {(addObjective) ? 
              <EatingObjectiveCard setAddCard={()=>setAddObjective(prev=>!prev)} />
            : null}
          {
            (eating && eating.goals.length > 0) ? eating.goals.map((item, i) => (
              <div key={i} className="editable-item-for-card top-margin-xs vertical-padding-xs left-padding-s right-padding-xs"> 
                <div className='editable-item-for-card-header'>
                  {(item.title && item.title !== "") ? 
                  <p key={i} className="body"><strong>{item.title}: </strong>{item.content}</p> : 
                  <p key={i} className="body">{item.content}</p> } 
                  {(item.id) ?
                  <div className="row-svg-icons left-margin-xs">
                    <button onClick={()=>{deleteObjective(item.id)}} className='carousel-btn'>
                      <img className="" src={RemoveIcon}/>
                    </button>
                  </div> : null }
                </div>
              </div>
            )) : null 
          }
          { (eating && (!addObjective && eating.goals.length === 0)) ?
            (
              <div className="submit-column vertical-margin-m">
                <img className="submission-image" src={Fisherman} alt="No goals yet"/>
                <p className="body secondary-color top-margin-s">Nenhum objetivo por aqui</p>
              </div>
            ) : null
          }
        </div>
      </EditableItemsCard2>

      {(hasWeightLoss) ? <WeightLossThumbnail /> : null }
      {(hasWeightGain) ? <HypertrophyThumbnail /> : null }
      
      <EditableItemsCard2 
        clsName="bottom-margin-m" 
        title="Plano semanal"
        icons={true}
        addCard={()=>setAddPlan(prev=>!prev)}
      >
        <div>
          {(addPlan) ?
              <EatingPlanSuggestionsCard suggestions={planSuggestions} setAddCard={()=>setAddPlan(prev=>!prev)} />
            : null}
          {
            (eating && eating.plan.length > 0) ? eating.plan.map((item, i) => (
              <PlanThumbnail item={item} i={i} deletePlan={deletePlan} />
            )) : null
          }
          { (eating && (!addPlan && eating.plan.length === 0)) ?
            (
              <div className="submit-column vertical-margin-m">
                <img className="submission-image" src={Fisherman} alt="No goals yet"/>
                <p className="body secondary-color top-margin-s">Sem planos por aqui</p>
              </div>
            ) : null
          }
        </div>
      </EditableItemsCard2>


          </div>
        </>
      </>
  )
}
