import React from "react"
import SimpleWaitMessage from "./SimpleWaitMessage"
import { Navigate } from "react-router-dom"
import SequenceMessage from "./SequenceMessage"
import FlorenceMessage from "./FlorenceMessage"
import { PortalContext } from "../../AuthenticatedUser"
import ObjectiveMessage from "./ObjectiveMessage"
import WeeklyPlanMessage from "./WeeklyPlanMessage"

export default function MessageBroker({ status, submitionItem, itemDate }) {

  const [messageState, setMessageState] = React.useState(0)
  const [localSequenceLength, setLocalSequenceLength] = React.useState({length: 0, today: true})

  const { sequenceLength, eating } = React.useContext(PortalContext)

  React.useEffect(() => {
    setLocalSequenceLength({...sequenceLength})
  }, [])

  const goToNextScreen = () => {
    setMessageState(prev => prev + 1)
  }
  
  if (status === "error") {
    return (
      <Navigate to="/loginrequired" />
    )
  }

  if (messageState === 0) {
    return (
      <SequenceMessage itemDate={itemDate} clickNext={()=>{goToNextScreen()}} sequence={localSequenceLength} />
    )
  } else if (messageState === 1) {
    return (
      <FlorenceMessage clickNext={()=>{goToNextScreen()}} submitionItem={submitionItem} />
    )
  } else if (messageState === 2) {
    if (eating.submission_pages.objective_check_in === false || submitionItem === "exercício") {
      goToNextScreen()
      return null
    } else {
      return (
        <ObjectiveMessage clickNext={()=>{goToNextScreen()}} />
      )
    }
  } else if (messageState === 3) {
    goToNextScreen()
    return null
    //if (eating.submission_pages.objective_check_in === false || submitionItem === "exercício") {
    //  goToNextScreen()
    //  return null
    //} else {
    //  return (
    //    <WeeklyPlanMessage clickNext={()=>{goToNextScreen()}} />
    //  )
    //}
  } else {
    if (status === "complete" && submitionItem === "refeição") {
      return (
        <Navigate 
          to="/eating/highlight" 
          state={{ message: "Sua refeição foi submetida com sucesso, e em alguns instantes ela aparecerá nessa página" }} 
        />
      )
    } else if (status === "complete" && submitionItem === "exercício") {
      return (
        <Navigate 
          to="/exercise/highlight" 
          state={{ message: "Seu exercício físico foi submetida com sucesso, e em alguns instantes ele aparecerá nessa página" }} 
        />
      )
    }

    return (
      <SimpleWaitMessage submitionItem={submitionItem} />
    )
  }
}
